import React from "react";

export const OrderChooseSex = ({onChange,setSex}) => {
    return (
        <>
        <h1 className="order-date-title">Выберите пол массажиста</h1>
            <div className="sex-container">
                <input type="radio" name="sex" id="sexMale" onChange={() => {setSex('Мужской'); onChange('DateScreen')}} />
                <label for="sexMale">Мужской</label>
                <input type="radio" name="sex" id="sexFemale" onChange={() => {setSex('Женский'); onChange('DateScreen')}} />
                <label for="sexFemale">Женский</label>
            </div>
        </>
    )
}