import React from "react";

const ServiceList = ({onChange}) =>{

    const position_names = ['Ручной массаж', 'Relax программы', 'Массаж лица', 'Детский массаж', 'Коррекция фигуры', 'Аппаратный массаж', 'NEW массажи', 'Акции'];

    const positionList = position_names.map((name) => 
    <div className='service-block' key={name} onClick={() => {onChange(name)}}>
        <h3>{name}</h3>
    </div>
    );



    return (
        <div className='services-list-box'>
            {positionList}
        </div>
    )

}

export default ServiceList